import { makeStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    display: 'inline-block',
    overflow: 'visible',
    backgroundColor: 'rgba(0, 0, 0, .1)',
    width: '100vw',
    height: '100vh'
  },
  title: {
    flexGrow: 1
  }
}

export default makeStyles(styles)
