import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Cropper from '../components/Cropper'
import { fileToBase64 } from '../utils/file'
import { addError } from '../store/actions/app'
import { getCroppedImage } from '../utils/image'
import { uploadImage } from '../store/actions/family'

function MemberImageForm({ member, id, afterSubmit }) {
  const dispatch = useDispatch()
  const [raw, setRaw] = useState(null)
  // const [fileName, setFileName] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)

  const onFileChosen = async e => {
    try {
      // setFileName(e.target.files[0].name)
      setRaw(await fileToBase64(e.target.files[0]))
    } catch (error) {
      dispatch(addError(error.message))
    }
  }

  const onCropChange = area => {
    setCroppedArea(area)
  }

  const upload = async () => {
    try {
      if (croppedArea) {
        if (croppedArea.width < 156) {
          dispatch(
            addError(
              `Member image must be at least 156 pixels wide. The width of cropped image is ${croppedArea.width}px.`
            )
          )
          return
        }

        const croppedImage = await getCroppedImage(raw, croppedArea)
        const formData = new FormData()

        const res = await fetch(croppedImage)
        const blob = await res.blob()
        formData.append('files', new File([blob], 'memberimage.jpg'))

        formData.append('ref', 'member')
        formData.append('field', 'image')
        formData.append('refId', id)

        await dispatch(uploadImage(id, formData))
        afterSubmit()
      }
    } catch (e) {
      dispatch(addError(`Could not upload image. ${e.message}`))
    }
  }

  return (
    <div>
      <input
        accept="image/*"
        id="image-upload-input"
        type="file"
        onChange={onFileChosen}
        style={{ display: 'none' }}
      />
      {!raw && (
        <div>
          {member.image ? (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="subtitle1" gutterBottom>
                Current member image:
              </Typography>
              <img
                src={`${process.env.REACT_APP_UPLOADS_BASE_URL}${member.image}`}
                alt="Member"
              />
            </div>
          ) : (
            <Typography variant="subtitle1" gutterBottom>
              Member has no image yet.
            </Typography>
          )}
          <label htmlFor="image-upload-input">
            <Button variant="contained" color="primary" component="span">
              Choose image
            </Button>
          </label>
        </div>
      )}

      {raw && (
        <>
          <Cropper image={raw} aspect={1 / 1} onChange={onCropChange} />
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={upload}
          >
            Upload
          </Button>
        </>
      )}
    </div>
  )
}

export default MemberImageForm
