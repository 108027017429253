import { makeStyles } from '@material-ui/core/styles'

const style = {
  root: {
    position: 'fixed',
    left: 32,
    bottom: 32
  }
}

const useStyles = makeStyles(style)

export { useStyles }
