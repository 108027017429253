import React, { useRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  useHasChildren,
  useRollChildren,
  useHasChildrenRolled,
  useUnrollChildren,
  useUnrollDescendants
} from './hooks'

function Roller({ memberId }) {
  const [menuToShow, setMenuToShow] = React.useState(null)
  const rollButtonRef = useRef(null)
  const unrollButtonRef = useRef(null)
  const hasChildren = useHasChildren(memberId)
  const rollChildren = useRollChildren(memberId)
  const hasChildrenRolled = useHasChildrenRolled(memberId)
  const unrollChildren = useUnrollChildren(memberId)
  const unrollDescendants = useUnrollDescendants(memberId)

  if (!hasChildren) {
    return null
  }
  const onRollClick = e => {
    e.stopPropagation()
    setMenuToShow('roll')
  }
  const onUnrollClick = e => {
    e.stopPropagation()
    setMenuToShow('unroll')
  }
  const onClickClose = e => {
    e.stopPropagation()
    setMenuToShow(null)
  }
  const onDoRollClick = e => {
    e.stopPropagation()
    setMenuToShow(null)
    rollChildren()
  }
  const onDoUnrollChildrenClick = e => {
    e.stopPropagation()
    setMenuToShow(null)
    unrollChildren()
  }
  const onDoUnrollDescendantsClick = e => {
    e.stopPropagation()
    setMenuToShow(null)
    unrollDescendants()
  }
  return hasChildrenRolled ? (
    <>
      <IconButton
        aria-label="add"
        color="primary"
        ref={unrollButtonRef}
        onClick={onUnrollClick}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={unrollButtonRef.current}
        keepMounted
        open={menuToShow === 'unroll'}
        onClose={onClickClose}
      >
        <MenuItem onClick={onDoUnrollChildrenClick}>
          Показати наступне покоління
        </MenuItem>
        <MenuItem onClick={onDoUnrollDescendantsClick}>
          Показати усіх нащадків
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <IconButton
        aria-label="add"
        color="primary"
        ref={rollButtonRef}
        onClick={onRollClick}
      >
        <ExpandLessIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={rollButtonRef.current}
        keepMounted
        open={menuToShow === 'roll'}
        onClose={onClickClose}
      >
        <MenuItem onClick={onDoRollClick}>Приховати нащадків</MenuItem>
      </Menu>
    </>
  )
}

export default Roller
