import React from 'react'
import { navigate } from '@reach/router'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import useIsAuthenticated from '../../hooks/useIsAuthenticated'
import Layout from '../../components/Layout'
import LoginForm from '../../forms/LoginForm'
import useStyles from './style'

function Login() {
  const classes = useStyles()
  const isAuthenticated = useIsAuthenticated()

  if (isAuthenticated === true) {
    navigate('/update/tree')
    return null
  }

  return (
    <Layout>
      <Container className={classes.root} maxWidth="sm">
        <Box mt={7} mb={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h1">
              Enter the Makari cabinet
            </Typography>
            <LoginForm />
          </Paper>
        </Box>
      </Container>
    </Layout>
  )
}

export default Login
