function normalizeDate(date) {
  return date instanceof Date
    ? date
    : new Date(typeof date === 'number' ? date * 1000 : date)
}

function dateToLocaleString(date) {
  if (!date) {
    return ''
  }
  const dateObj = normalizeDate(date)
  return dateObj.toLocaleDateString('uk-UA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

function dateToShortString(date) {
  if (!date) {
    return ''
  }
  const dateObj = normalizeDate(date)
  return dateObj.toLocaleDateString('uk-UA')
}

function composeDate(date) {
  if (!date) {
    date = {}
  }
  return [date.day, date.month, date.year].filter(x => !!x).join('.')
}

export { dateToLocaleString, dateToShortString, composeDate }
