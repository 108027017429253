import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { messages } from '../utils/validation'
import { login as fetchLogin } from '../api/auth'
import useLogin from '../hooks/useLogin'
import { addError } from '../store/actions/app'

function LoginForm() {
  const { register, handleSubmit, errors } = useForm()
  const dispatch = useDispatch()
  const login = useLogin()

  const onSubmit = async data => {
    try {
      const { user, jwt } = await fetchLogin(data)
      if (!jwt || !user) {
        throw new Error('Server response is incomplete.')
      }
      login(jwt, user)
    } catch (e) {
      dispatch(addError(e.message))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        inputRef={register({ required: messages.required })}
        name="identifier"
        error={!!errors.identifier}
      />
      <TextField
        variant="outlined"
        label="Password"
        type="password"
        fullWidth
        margin="normal"
        inputRef={register({ required: messages.required })}
        error={!!errors.password}
        name="password"
      />
      <Button type="submit" color="primary" variant="contained" size="large">
        Login
      </Button>
    </form>
  )
}

export default LoginForm
