import React from 'react'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

function ApproveRemovingDialog({ name, open, onClose, doRemove }) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="remove-member-dialog-title"
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="remove-member-dialog-title">
        Not a family member?
      </DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete {name} from the family tree?</p>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          onClick={doRemove}
        >
          Remove
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          style={{ marginLeft: 24 }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ApproveRemovingDialog
