import { makeStyles } from '@material-ui/core/styles'

// const styles = theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2)
//     }
//   }
// })

const styles = {
  root: {},
  cropContainer: {
    position: 'relative',
    minHeight: 320,
    width: '100%'
  },

  controls: {
    width: '100%',
    margin: '16px 0'
  }
}

export default makeStyles(styles)
