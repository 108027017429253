function getLocationString(location) {
  if (!location) location = {}
  return [location.town, location.region, location.country]
    .filter(val => !!val)
    .join(', ')
}

function getShortLocationString(location) {
  if (!location) location = {}
  return [location.town, location.country].filter(val => !!val).join(', ')
}

export { getLocationString, getShortLocationString }
