import { makeStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    position: 'absolute',
    pointerEvents: 'none'
  }
}

export const useStyles = makeStyles(styles)
