import React from 'react'
import { navigate } from '@reach/router'
import useIsAuthenticated from '../../hooks/useIsAuthenticated'

function Update({ children }) {
  const isAuthenticated = useIsAuthenticated()

  if (isAuthenticated === false) {
    navigate('/login')
    return null
  }

  return <>{children}</>
}

export default Update
