import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { shiftNotification } from '../../store/actions/app'
import useStyles from './style'

function Notifier() {
  const classes = useStyles()
  const notifications = useSelector(state => state.app.notifications)
  const dispatch = useDispatch()

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleClose = () => {
    dispatch(shiftNotification())
  }

  return (
    <div className={classes.root}>
      {notifications.length > 0 && (
        <Snackbar open autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={notifications[0].type}>
            {notifications[0].message}
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}

export default Notifier
