import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
})

export default makeStyles(styles)
