import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { messages } from '../utils/validation'
import { updateMember, removeMember } from '../store/actions/family'
import ApproveRemovingDialog from '../dialogs/ApproveRemovingDialog'

const daysAvailable = []
let d = 1
do {
  daysAvailable.push('' + d)
  d++
} while (d <= 31)

const monthsAvailable = []
let m = 1
do {
  monthsAvailable.push('' + m)
  m++
} while (m <= 12)

const yearsAvailable = []
let y = new Date().getFullYear()
do {
  yearsAvailable.push('' + y)
  y--
} while (y >= 1700)

function EditMemberForm({
  member,
  id,
  afterSubmit,
  allowRemoving,
  afterRemove
}) {
  const [showApproveRemoving, setShowApproveRemoving] = useState(false)
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, control } = useForm()
  const onSubmit = async data => {
    const result = await dispatch(updateMember(id, data))
    if (result) {
      afterSubmit()
    }
  }

  const doRemoveMember = async () => {
    const result = await dispatch(removeMember(id))
    if (result) {
      afterRemove()
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextField
              label="Name"
              variant="outlined"
              defaultValue={member.name}
              fullWidth
              inputRef={register({ required: messages.required })}
              name="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Last name"
              variant="outlined"
              defaultValue={member.lastName}
              fullWidth
              inputRef={register}
              name="lastName"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Maiden name"
              variant="outlined"
              defaultValue={member.maidenName}
              fullWidth
              inputRef={register}
              name="maidenName"
              error={!!errors.maidenName}
              helperText={errors?.maidenName?.message}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Residence location</Typography>
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Country"
              variant="outlined"
              defaultValue={member.location?.country || ''}
              fullWidth
              inputRef={register}
              name="location.country"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Region"
              variant="outlined"
              defaultValue={member.location?.region || ''}
              fullWidth
              inputRef={register}
              name="location.region"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Town"
              variant="outlined"
              defaultValue={member.location?.town || ''}
              fullWidth
              inputRef={register}
              name="location.town"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Birth</Typography>
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="birthDate.day"
              defaultValue={member.birthDate?.day || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={daysAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Day" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="birthDate.month"
              defaultValue={member.birthDate?.month || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={monthsAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Month" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="birthDate.year"
              defaultValue={member.birthDate?.year || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={yearsAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Year" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>

          <Grid item sm={4}>
            <TextField
              label="Country"
              variant="outlined"
              defaultValue={member.birthLocation?.country || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.country"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Region"
              variant="outlined"
              defaultValue={member.birthLocation?.region || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.region"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Town"
              variant="outlined"
              defaultValue={member.birthLocation?.town || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.town"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Death</Typography>
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="isDead"
              defaultValue={member.isDead || false}
              render={({ onChange, value }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e, val) => onChange(val)}
                    />
                  }
                  label="Is dead"
                />
              )}
            />
          </Grid>
          <Grid item sm={8} />

          <Grid item sm={4}>
            <Controller
              control={control}
              name="deathDate.day"
              defaultValue={member.deathDate?.day || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={daysAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Day" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="deathDate.month"
              defaultValue={member.deathDate?.month || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={monthsAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Month" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="deathDate.year"
              defaultValue={member.deathDate?.year || null}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={yearsAvailable}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Year" />
                  )}
                  value={value}
                  onChange={(e, val) => onChange(val)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
            >
              Save
            </Button>
            {allowRemoving && (
              <Button
                color="secondary"
                variant="contained"
                size="large"
                style={{ marginLeft: 24 }}
                onClick={() => setShowApproveRemoving(true)}
              >
                Remove
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <ApproveRemovingDialog
        open={showApproveRemoving}
        name={member.name}
        onClose={() => setShowApproveRemoving(false)}
        doRemove={doRemoveMember}
      />
    </>
  )
}

export default EditMemberForm
