import { makeStyles } from '@material-ui/core/styles'

const style = {
  root: {
    display: 'inline-block',
    overflow: 'scroll',
    width: '100vw',
    height: 'calc(100vh - 64px)',
    cursor: 'grab'
  }
}

const useStyles = makeStyles(style)

export { useStyles }
