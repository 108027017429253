import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setZoomLevel } from '../../store/actions/ui'

function ZoomContainer({ children }) {
  const zoomLevel = useSelector(state => state.ui.zoomLevel)

  const membersGeometry = useSelector(state => state.ui.membersGeometry)
  const dispatch = useDispatch()
  useEffect(() => {
    if (membersGeometry && Object.keys(membersGeometry).length === 0) {
      dispatch(setZoomLevel(1))
    }
  }, [membersGeometry])

  return (
    <div
      style={{
        transform: `scale(${zoomLevel})`,
        transformOrigin: 'top center',
        pointerEvents: 'none'
      }}
    >
      {children}
    </div>
  )
}

export default ZoomContainer
