import React, { useState, useCallback } from 'react'
import BaseCropper from 'react-easy-crop'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import useStyles from './style'

function Cropper({ image, aspect, onChange }) {
  const classes = useStyles()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onChange(croppedAreaPixels)
    },
    [onChange]
  )
  const zoomIn = () => {
    setZoom(val => (val < 5 ? val + 0.5 : val))
  }
  const zoomOut = () => {
    setZoom(val => (val > 1 ? val - 0.5 : val))
  }

  return (
    <div className={classes.root}>
      <div className={classes.cropContainer}>
        <BaseCropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={zoomIn}>+</Button>
          <Button onClick={zoomOut}>-</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default Cropper
