import { get, post } from '../utils/http'

async function getFamilyTree(alias) {
  return await get(`trees/${alias}.tree.json`)
}

async function getExtendedData(alias) {
  return await get(`trees/${alias}.extended.json`)
}

async function rebuild(id) {
  return await post(`families/${id}/rebuild`)
}

export { getFamilyTree, getExtendedData, rebuild }
