import { types } from '../actions/app'
import { app as initialState } from '../initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ERROR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            type: 'error',
            message:
              action.message ||
              'Oops! An error happend. Please, try to reload the page.'
          }
        ]
      }
    case types.SHIFT_NOTIFICATION: {
      const notifications = [...state.notifications]
      notifications.shift()
      return {
        ...state,
        notifications
      }
    }
    default:
      return state
  }
}
