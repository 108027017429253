import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { messages } from '../utils/validation'
import { addParent } from '../store/actions/family'

function AddParentForm({ memberId }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    dispatch(addParent(memberId, data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Ім'я"
        variant="outlined"
        fullWidth
        margin="normal"
        inputRef={register({ required: messages.required })}
        name="name"
        error={!!errors.name}
        helperText={errors?.name?.message}
      />
      <Button type="submit" color="primary" variant="contained" size="large">
        Save
      </Button>
    </form>
  )
}

export default AddParentForm
