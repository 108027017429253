import { makeStyles } from '@material-ui/core/styles'
import {
  MEMBER_HEIGHT,
  MEMBER_IMAGE_RATIO,
  MATRIMONY_STEP_DISTANCE
} from '../../const'

const styles = {
  root: {
    display: 'inline-block',
    minWidth: 125,
    verticalAlign: 'top',
    paddingBottom: MATRIMONY_STEP_DISTANCE,

    '&.is-partner': {
      paddingTop: MATRIMONY_STEP_DISTANCE,
      paddingBottom: 0
    }
  },
  card: {
    height: MEMBER_HEIGHT,
    cursor: 'auto',
    pointerEvents: 'auto',
    userSelect: 'auto',
    position: 'relative'
  },
  content: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    textAlign: 'left'
  },
  contents: {
    flex: '1 0 auto'
  },
  image: {
    width: MEMBER_HEIGHT,
    height: MEMBER_HEIGHT * MEMBER_IMAGE_RATIO
  },
  bw: {
    filter: 'grayscale()'
  }
}

export const useStyles = makeStyles(styles)
