import { types } from '../actions/flags'
import { flags as initialState } from '../initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TREE_FETCHED:
      return { ...state, treeFetched: action.value }
    case types.SET_MEMBERS_RENDERED:
      return { ...state, membersRendered: action.value }
    case types.SET_TREE_GEOMETRY_CALCULATED:
      return { ...state, treeGeometryCalculated: action.value }
    case types.SET_MEMBERS_GEOMETRY_CALCULATED:
      return { ...state, membersGeometryCalculated: action.value }
    case types.SET_RELATIONS_GEOMETRY_CALCULATED:
      return { ...state, relationsGeometryCalculated: action.value }
    case types.SET_RELATIONS_PAINTED:
      return { ...state, relationsPainted: action.value }
    case types.SET_FETCH_NEEDED:
      return { ...state, fetchNeeded: action.value }
    case types.SET_EXTENDED_DATA_FETCHED:
      return { ...state, extendedDataFetched: action.value }
    case types.SET_TREE_REDRAW:
      return { ...state, treeRedraw: action.value }
    default:
      return state
  }
}
