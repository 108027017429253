import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getItem as getItemFromStorage } from '../../utils/storage'
import { setJwt, setUserData, setAuthed } from '../../store/actions/user'

function Auther({ children }) {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (localStorage) {
      try {
        const jwt = getItemFromStorage('jwt')
        const userData = getItemFromStorage('userData')
        if (jwt && userData && typeof userData === 'object') {
          dispatch(setJwt(jwt))
          dispatch(setUserData(userData))
        }
      } catch (e) {
        // We consider user not logged in in case of error.
      }
    }
    dispatch(setAuthed(true))
  }, [])

  return <>{children}</>
}

export default Auther
