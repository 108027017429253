import React from 'react'
import { navigate } from '@reach/router'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import useIsAuthenticated from '../../hooks/useIsAuthenticated'
import useLogout from '../../hooks/useLogout'
import { addError } from '../../store/actions/app'

function Menu(onClose) {
  const isAuthenticated = useIsAuthenticated()
  const logout = useLogout()

  const onLogoutClick = () => {
    try {
      logout()
      onClose()
      navigate('/')
    } catch (e) {
      addError(e.message)
    }
  }

  return (
    <List>
      {isAuthenticated ? (
        <>
          <ListItem button component={Link} href="/update/tree">
            <ListItemText primary="Редагувати дерево" />
          </ListItem>
          <ListItem button onClick={onLogoutClick}>
            <ListItemText primary="Вийти" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem button component={Link} href="/login">
            <ListItemText primary="Ввійти" />
          </ListItem>
        </>
      )}
    </List>
  )
}

export default Menu
