import { createSelector } from 'reselect'
import { countTreeMembers, countRolledMembersAndDescendants } from './family'

const renderedMembersCounterSelector = state => state.ui.renderedMembersCounter
const membersGeometrySelector = state => state.ui.membersGeometry

const areAllMembersRendered = createSelector(
  [
    renderedMembersCounterSelector,
    countTreeMembers,
    countRolledMembersAndDescendants
  ],
  (renderedCounter, allCounter, rolledCounter) =>
    allCounter && !(allCounter - rolledCounter > renderedCounter)
)

const areAllMembersGeometryCalculated = createSelector(
  [membersGeometrySelector, countTreeMembers, countRolledMembersAndDescendants],
  (membersGeometry, allCounter, rolledCounter) => {
    return (
      allCounter &&
      !(allCounter - rolledCounter > Object.keys(membersGeometry).length)
    )
  }
)

export { areAllMembersRendered, areAllMembersGeometryCalculated }
