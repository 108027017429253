import React from 'react'
import { useStyles } from './style'
import Member from '../Member'
import MemberGroup from '../MemberGroup'

function Matrimony({
  member,
  isPrimary,
  matrimony,
  memberComponent: MemberComponent
}) {
  const classes = useStyles()
  const children = isPrimary
    ? [...(member.children || []), ...(matrimony.children || [])]
    : matrimony.children || []
  return (
    <div className={classes.root}>
      {isPrimary &&
        (MemberComponent ? (
          <MemberComponent memberId={member.id} />
        ) : (
          <Member memberId={member.id} />
        ))}

      <div className={classes.partner}>
        {MemberComponent ? (
          <MemberComponent memberId={matrimony.partnerId} isPartner={true} />
        ) : (
          <Member memberId={matrimony.partnerId} isPartner={true} />
        )}
      </div>

      <div className={classes.children}>
        {children.map((item, key) => (
          <MemberGroup
            group={item}
            key={key}
            memberComponent={MemberComponent}
          />
        ))}
      </div>
    </div>
  )
}

export default Matrimony
