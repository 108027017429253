import React from 'react'
import { Provider } from 'react-redux'
import { Router } from '@reach/router'
import { ThemeProvider } from '@material-ui/core/styles'
import store from '../../store'
import { Tree, Login, Update, UpdateTree } from '../../pages'
import GlobalLoader from '../GlobalLoader'
import Notifier from '../Notifier'
import Auther from '../Auther'
import theme from './theme'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Auther>
          <Router>
            <Login path="/login" />
            <Tree path="/" />
            <Update path="/update">
              <UpdateTree path="/tree" />
            </Update>
          </Router>
        </Auther>
        <GlobalLoader />
        <Notifier />
      </ThemeProvider>
    </Provider>
  )
}

export default App
