import { useDispatch } from 'react-redux'
import { setItem as setItemToStorage } from '../utils/storage'
import { setJwt, setUserData } from '../store/actions/user'

function useLogin() {
  const dispatch = useDispatch()
  function login(jwt, userData) {
    dispatch(setJwt(jwt))
    dispatch(setUserData(userData))
    setItemToStorage('jwt', jwt)
    setItemToStorage('userData', userData)
  }

  return login
}

export default useLogin
