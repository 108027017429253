function paint(canvas, lines) {
  const ctx = canvas.getContext('2d')

  // Prevent darkening of overlapped lines
  ctx.translate(0.5, 0.5)

  // Draw
  lines.forEach(line => {
    ctx.beginPath()
    ctx.moveTo(line[0].x, line[0].y)
    let i = 1
    while (i < line.length) {
      ctx.lineTo(line[i].x, line[i].y)
      i += 1
    }
    ctx.stroke()
  })
}

function clear(canvas) {
  const ctx = canvas.getContext('2d')
  ctx.clearRect(0, 0, canvas.width, canvas.height)
}

export { paint, clear }
