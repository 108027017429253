import React from 'react'
import Layout from '../components/Layout'
import TreeContainer from '../components/TreeContainer'
import TreeComponent from '../components/Tree'
import Zoomer from '../components/Zoomer'

function Tree() {
  return (
    <Layout>
      <TreeContainer>
        <TreeComponent />
      </TreeContainer>
      <Zoomer />
    </Layout>
  )
}

export default Tree
