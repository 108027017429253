import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import AddParentForm from '../forms/AddParentForm'
import AddChildForm from '../forms/AddChildForm'
import AddPartnerForm from '../forms/AddPartnerForm'

const memberTypes = ['parent', 'child', 'partner']

function AddMember({ open, onClose, memberId, noParentAdding }) {
  const [memberType, setMemberType] = useState(null)

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="add-member-dialog-title"
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="add-member-dialog-title">
        Add a {memberType || 'member'}
      </DialogTitle>
      <DialogContent>
        {!memberType && (
          <List>
            {memberTypes.map(item =>
              item === 'parent' && noParentAdding ? (
                ''
              ) : (
                <ListItem key={item}>
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={() => setMemberType(item)}
                  >{`Add ${item}`}</Button>
                </ListItem>
              )
            )}
          </List>
        )}
        {memberType === 'parent' && <AddParentForm memberId={memberId} />}
        {memberType === 'child' && <AddChildForm memberId={memberId} />}
        {memberType === 'partner' && <AddPartnerForm memberId={memberId} />}
      </DialogContent>
    </Dialog>
  )
}

export default AddMember
