import { useSelector } from 'react-redux'
import { parseJWT } from '../utils/auth'
import { removeItem as removeItemFromStorage } from '../utils/storage'

function useIsAuthenticated() {
  const token = useSelector(state => state.user.jwt)
  const authed = useSelector(state => state.user.authed)

  if (!authed) {
    return null
  }

  if (!token) {
    return false
  }

  const { exp } = parseJWT(token)
  const tokenIsValid = exp > new Date().getTime() / 1000
  if (!tokenIsValid) {
    removeItemFromStorage('jwt')
    removeItemFromStorage('user')
  }

  return tokenIsValid
}

export default useIsAuthenticated
