import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import EditMemberForm from '../forms/EditMemberForm'
import MemberImageForm from '../forms/MemberImageForm'
import MemberParentsForm from '../forms/MemberParentsForm'

function EditMember({
  open,
  onClose,
  member,
  id,
  allowRemoving,
  isMatrimonyPartner,
  parents
}) {
  const [tabValue, setTabValue] = useState(0)

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="edit-member-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="edit-member-dialog-title">
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, val) => setTabValue(val)}
          aria-label="Update member"
        >
          <Tab label="Update" />
          <Tab label="Image" />
          {!isMatrimonyPartner && <Tab label="Parents" />}
        </Tabs>
      </DialogTitle>
      <DialogContent>
        {tabValue === 0 && (
          <EditMemberForm
            member={member}
            id={id}
            afterSubmit={onClose}
            afterRemove={onClose}
            allowRemoving={allowRemoving}
          />
        )}
        {tabValue === 1 && (
          <MemberImageForm member={member} id={id} afterSubmit={onClose} />
        )}
        {!isMatrimonyPartner && tabValue === 2 && (
          <MemberParentsForm parents={parents} id={id} afterSubmit={onClose} />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default EditMember
