import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useCallback } from 'react'
import { onMemberRendered, saveMemberGeometry } from '../../store/actions/ui'
import { getAllParents, getAllChildren } from '../../store/selectors/family'

function useMemberRendered() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onMemberRendered())
  }, [dispatch])
}

function useCalculateMemberGeometry(id, ref) {
  const treeGeoCalculated = useSelector(
    state => state.flags.treeGeometryCalculated
  )
  const treeGeo = useSelector(state => state.ui.treeGeometry)
  const dispatch = useDispatch()
  useEffect(() => {
    if (treeGeoCalculated && treeGeo) {
      const rect = ref.current.getBoundingClientRect()
      const geo = {
        left: Math.round(rect.left - treeGeo.left),
        right: Math.round(rect.right - treeGeo.left),
        top: Math.round(rect.top - treeGeo.top),
        bottom: Math.round(rect.bottom - treeGeo.top)
      }

      dispatch(saveMemberGeometry({ id, value: geo }))
    }
  }, [treeGeoCalculated, treeGeo, id, ref, dispatch])
}

function useGetParents(id) {
  const allParents = useSelector(getAllParents)
  return allParents[id] || []
}

function useAllowRemoving(id) {
  const allChildren = useSelector(getAllChildren)
  return allChildren[id].length === 0
}

export {
  useMemberRendered,
  useCalculateMemberGeometry,
  useGetParents,
  useAllowRemoving
}
