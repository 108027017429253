import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import MenuIcon from '@material-ui/icons/Menu'
import Link from '@material-ui/core/Link'
import Menu from './Menu'
import useStyles from './style'

function Layout({ children }) {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Link href="/" color="inherit" className={classes.title}>
            <Typography variant="h6" component="h1">
              Родина Макарів
            </Typography>
          </Link>
          <IconButton
            edge="end"
            color="inherit"
            className={classes.menuButton}
            aria-label="open menu"
            onClick={() => setOpenMenu(val => !val)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={openMenu} onClose={() => setOpenMenu(false)}>
        <Menu onClose={() => setOpenMenu(false)} />
      </Drawer>
      {children}
    </div>
  )
}

export default Layout
