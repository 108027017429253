import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFamilyTree, fetchExtendedData } from '../../store/actions/family'
import { setTreeRedraw } from '../../store/actions/flags'
import {
  calculateRelationsGeometry,
  saveTreeGeometry
} from '../../store/actions/ui'

function useFetchTree(alias) {
  const fetchNeeded = useSelector(state => state.flags.fetchNeeded)
  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchNeeded) {
      dispatch(fetchFamilyTree(alias))
    }
  }, [dispatch, alias, fetchNeeded])
}

function useCalculateTreeGeometry(treeRef) {
  const membersRendered = useSelector(state => state.flags.membersRendered)
  const dispatch = useDispatch()

  useEffect(() => {
    if (membersRendered) {
      const geo = {}
      // Count tree geometry after tree rendering
      requestAnimationFrame(() => {
        const rect = treeRef.current.getBoundingClientRect()
        ;['left', 'top', 'right', 'bottom'].forEach(key => {
          geo[key] = Math.round(rect[key])
        })
        dispatch(saveTreeGeometry(geo))
      })
      // }, 5000)
    }
  }, [treeRef, membersRendered, dispatch])
}

function useCalculateRelationsGeometry(treeRef) {
  const membersGeoCalculated = useSelector(
    state => state.flags.membersGeometryCalculated
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (membersGeoCalculated) {
      dispatch(calculateRelationsGeometry())
    }
  }, [membersGeoCalculated, dispatch, treeRef])
}

function useFetchExtendedData(alias) {
  const relationsPainted = useSelector(state => state.flags.relationsPainted)
  const dispatch = useDispatch()

  useEffect(() => {
    if (relationsPainted) {
      dispatch(fetchExtendedData(alias))
    }
  }, [dispatch, alias, relationsPainted])
}

function useDrawKey() {
  const [drawKey, setDrawKey] = useState(null)
  const redrawFlag = useSelector(state => state.flags.treeRedraw)
  const dispatch = useDispatch()
  useEffect(() => {
    if (redrawFlag) {
      setDrawKey(Math.random())
      dispatch(setTreeRedraw(false))
    }
  }, [redrawFlag, setDrawKey, dispatch, setTreeRedraw])
  return drawKey
}

export {
  useFetchTree,
  useCalculateTreeGeometry,
  useCalculateRelationsGeometry,
  useFetchExtendedData,
  useDrawKey
}
