const initialState = {
  app: {
    notifications: []
  },
  flags: {
    fetchNeeded: true,
    treeFetched: false,
    membersRendered: false,
    treeGeometryCalculated: false,
    membersGeometryCalculated: false,
    relationsGeometryCalculated: false,
    relationsPainted: false,
    extendedDataFetched: false,
    treeRedraw: false
  },
  family: {
    // alias: '',
    id: null,
    tree: null,
    members: null,
    matrimonies: null,
    rolledMembers: [],
    gotoMember: null
  },
  ui: {
    showGlobalLoader: false,
    renderedMembersCounter: 0,
    treeGeometry: null,
    membersGeometry: null,
    relationsGeometry: null,
    zoomLevel: 1
  },
  user: {
    data: null,
    jwt: null,
    // if Auther component already checked for credentials in `storage`
    authed: false
  }
}

export const { app, flags, ui, family, user } = initialState
