import { getItem as getFromStorage } from '../utils/storage'
import store from '../store'
import { addError } from '../store/actions/app'

function request(url, options = {}) {
  // Normalize url
  const apiUrl =
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `${process.env.REACT_APP_API_URL}/${url}`

  // Set default request options
  const defaultOptions = {
    headers: {
      Accept: 'application/json'
    }
  }

  // POST,PATCH,PUT
  // if (['POST', 'PATCH', 'PUT'].includes(options.method)) {
  //   defaultOptions.headers['Content-Type'] = 'application/json'
  // }

  // Authorization header
  const token = getFromStorage('jwt')
  if (token) {
    defaultOptions.headers.Authorization = `Bearer ${token}`
  }

  // Finalize options
  const requestOptions = {
    ...defaultOptions,
    ...options,
    headers: { ...defaultOptions.headers, ...(options.headers || {}) }
  }
  // Handle json request
  if (requestOptions.body) {
    if (!(requestOptions.body instanceof FormData)) {
      requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = JSON.stringify(requestOptions.body)
    }
  }

  // Fetch request
  return (
    fetch(apiUrl, requestOptions)
      // .then(response => {
      //   return response.ok ? response : Promise.reject(response.statusText)
      // })
      .then(response => {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          return response.json()
        }
        return Promise.reject(new Error('This content type not excpected.'))
      })
      .then(response => {
        if (response.error) {
          throw new Error(
            // Wild path to Strapi error message (tested on `auth/local` endpoint)
            response?.data?.[0]?.messages?.[0]?.message || response.error
          )
        }
        return response
      })
      .catch(error => {
        store.dispatch(addError(error.message))
        // throw error
      })
  )
}

/**
 * Sends GET http request
 * @param {[String]} url
 * @param {Object} [options={}]
 * @return {[Promise]}
 */
function get(url, options = {}) {
  options.method = 'GET'
  return request(url, options)
}

/**
 * Sends POST http request
 * @param {[String]} url
 * @param {Object} [options={}]
 * @return {[Promise]}
 */
function post(url, body, options = {}) {
  options.method = 'POST'
  options.body = body
  return request(url, options)
}

/**
 * Sends PUT http request
 * @param {[String]} url
 * @param {Object} [options={}]
 * @return {[Promise]}
 */
function put(url, body, options = {}) {
  options.method = 'PUT'
  options.body = body
  return request(url, options)
}

/**
 * Sends DELETE http request
 * @param {[String]} url
 * @param {Object} [options={}]
 * @return {[Promise]}
 */
function requestDelete(url, options = {}) {
  options.method = 'DELETE'
  return request(url, options)
}

export { get, post, put, requestDelete }
