import React from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './style'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

function GlobalLoader() {
  const showLoader = useSelector(state => state.ui.showGlobalLoader)
  const classes = useStyles()

  return (
    <>
      {
        <Backdrop className={classes.backdrop} open={showLoader}>
          <CircularProgress />
        </Backdrop>
      }
    </>
  )
}

export default GlobalLoader
