import React from 'react'
import { useStyles } from './style'
import Member from '../Member'
import Matrimony from '../Matrimony'
import { useIsRolled } from './hooks'

function MemberGroup({
  group: { matrimonies, ...group },
  memberComponent: MemberComponent
}) {
  const memberId = group.id
  const classes = useStyles()
  const isRolled = useIsRolled(memberId)
  if (isRolled) {
    return null
  }
  const [mainMatrimony, ...otherMatrimonies] = matrimonies || []

  return (
    <div className={classes.root}>
      {mainMatrimony ? (
        <Matrimony
          member={group}
          isPrimary={true}
          matrimony={mainMatrimony}
          memberComponent={MemberComponent}
        />
      ) : MemberComponent ? (
        <MemberComponent memberId={memberId} />
      ) : (
        <Member memberId={memberId} />
      )}

      {otherMatrimonies &&
        otherMatrimonies.map(matrimony => (
          <Matrimony
            matrimony={matrimony}
            key={matrimony.id}
            memberComponent={MemberComponent}
          />
        ))}
      {!mainMatrimony && group.children && (
        <div className={classes.children}>
          {group.children &&
            group.children.map((item, key) => (
              <MemberGroup
                group={item}
                key={key}
                memberComponent={MemberComponent}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default MemberGroup
