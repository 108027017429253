export const MEMBER_DISTANCE_X = 40
export const MEMBER_DISTANCE_Y = 60
export const MEMBER_HEIGHT = 100
export const MEMBER_IMAGE_RATIO = 1
export const MATRIMONY_STEP_DISTANCE = 40

// Zoom
export const ZOOM_STEP = 0.15
export const ZOOM_MAX = 1.6
export const ZOOM_MIN = 0.4
