const types = {
  SET_JWT: 'SET_JWT',
  SET_DATA: 'SET_DATA',
  SET_AUTHED: 'SET_AUTHED'
}

function setJwt(value) {
  return {
    type: types.SET_JWT,
    value
  }
}

function setData(value) {
  return {
    type: types.SET_DATA,
    value
  }
}

function setAuthed(value) {
  return {
    type: types.SET_AUTHED,
    value
  }
}

export { types, setJwt, setData as setUserData, setAuthed }
