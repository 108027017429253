import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { paint, clear } from './painter'
import { setRelationsPainted } from '../../store/actions/flags'

function usePaint(canvas) {
  const dispatch = useDispatch()
  const relationsGeometryCalculated = useSelector(
    state => state.flags.relationsGeometryCalculated
  )
  const relationsGeometry = useSelector(state => state.ui.relationsGeometry)

  useEffect(() => {
    if (canvas) {
      if (relationsGeometryCalculated) {
        paint(canvas, relationsGeometry)
        dispatch(setRelationsPainted(true))
      } else {
        clear(canvas)
      }
    }
  }, [canvas, relationsGeometryCalculated])
}

export { usePaint }
