import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { updateMember } from '../store/actions/family'
import { getMainMembers, getPartnersList } from '../store/selectors/family'

function MemberParentsForm({ id: memberId, parents: parentIds, afterSubmit }) {
  const dispatch = useDispatch()
  const allMainMembers = useSelector(getMainMembers)
  const partnersList = useSelector(getPartnersList)
  const membersData = useSelector(state => state.family.members)
  const { handleSubmit, control } = useForm()

  const onSubmit = data => {
    const submissionData = {
      parents: Object.values(data).filter(id => id.length > 0)
    }
    dispatch(updateMember(memberId, submissionData, true))
    afterSubmit()
  }
  const mainParentId = parentIds.find(p => allMainMembers.includes(p))
  const otherParentId = parentIds.find(p => p != mainParentId) || ''
  const mainParentData = membersData.get(mainParentId)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6">Choose parents</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="parent_1"
            defaultValue={mainParentId}
            render={({ onChange, value }) => (
              <Select
                variant="outlined"
                disabled
                value={value}
                onChange={onChange}
              >
                <MenuItem value={mainParentId}>{mainParentData.name}</MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="parent_2"
            defaultValue={otherParentId}
            render={({ onChange, value }) => (
              <Select
                variant="outlined"
                style={{ minWidth: 180 }}
                value={value}
                onChange={onChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {partnersList[mainParentId].map(id => (
                  <MenuItem value={id} key={id}>
                    {membersData.get(id).name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Button
        style={{ marginTop: 16 }}
        type="submit"
        color="primary"
        variant="contained"
        size="large"
      >
        Save
      </Button>
    </form>
  )
}

export default MemberParentsForm
