const types = {
  SET_TREE_FETCHED: 'SET_TREE_FETCHED',
  SET_MEMBERS_RENDERED: 'SET_MEMBERS_RENDERED',
  SET_TREE_GEOMETRY_CALCULATED: 'SET_TREE_GEOMETRY_CALCULATED',
  SET_MEMBERS_GEOMETRY_CALCULATED: 'SET_MEMBERS_GEOMETRY_CALCULATED',
  SET_RELATIONS_GEOMETRY_CALCULATED: 'SET_RELATIONS_GEOMETRY_CALCULATED',
  SET_RELATIONS_PAINTED: 'SET_RELATIONS_PAINTED',
  SET_FETCH_NEEDED: 'SET_FETCH_NEEDED',
  SET_EXTENDED_DATA_FETCHED: 'SET_EXTENDED_DATA_FETCHED',
  SET_TREE_REDRAW: 'SET_TREE_REDRAW'
}

function setFetchNeeded(value) {
  return {
    type: types.SET_FETCH_NEEDED,
    value
  }
}

function setTreeFetched(value) {
  return {
    type: types.SET_TREE_FETCHED,
    value
  }
}

function setMembersRendered(value) {
  return {
    type: types.SET_MEMBERS_RENDERED,
    value
  }
}

function setTreeGeometryCalculated(value) {
  return {
    type: types.SET_TREE_GEOMETRY_CALCULATED,
    value
  }
}

function setMembersGeometryCalculated(value) {
  return {
    type: types.SET_MEMBERS_GEOMETRY_CALCULATED,
    value
  }
}

function setRelationsGeometryCalculated(value) {
  return {
    type: types.SET_RELATIONS_GEOMETRY_CALCULATED,
    value
  }
}

function setRelationsPainted(value) {
  return {
    type: types.SET_RELATIONS_PAINTED,
    value
  }
}

function setExtendedDataFetched(value) {
  return {
    type: types.SET_EXTENDED_DATA_FETCHED,
    value
  }
}

function setTreeRedraw(value) {
  return {
    type: types.SET_TREE_REDRAW,
    value
  }
}

export {
  types,
  setTreeFetched,
  setMembersRendered,
  setTreeGeometryCalculated,
  setMembersGeometryCalculated,
  setRelationsGeometryCalculated,
  setRelationsPainted,
  setFetchNeeded,
  setExtendedDataFetched,
  setTreeRedraw
}
