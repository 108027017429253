import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { useDispatch } from 'react-redux'
import { useStyles } from './style'
import { incrementZoomLevel, decrementZoomLevel } from '../../store/actions/ui'

function Zoomer() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onAddZoomClick = e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(incrementZoomLevel())
  }

  const onRemoveZoomClick = e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(decrementZoomLevel())
  }

  return (
    <div className={classes.root}>
      <ButtonGroup
        orientation="vertical"
        color="primary"
        variant="contained"
        size="small"
        aria-label="Zoom tree"
      >
        <Button onClick={onAddZoomClick}>
          <AddIcon />
        </Button>
        <Button onClick={onRemoveZoomClick}>
          <RemoveIcon />
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default Zoomer
