import {
  areAllMembersRendered,
  areAllMembersGeometryCalculated
} from '../selectors/ui'
import {
  setMembersRendered,
  setTreeGeometryCalculated,
  setMembersGeometryCalculated,
  setRelationsGeometryCalculated
} from './flags'
import {
  MEMBER_DISTANCE_X,
  MEMBER_DISTANCE_Y,
  ZOOM_STEP,
  ZOOM_MIN,
  ZOOM_MAX,
  MATRIMONY_STEP_DISTANCE
} from '../../const'

const types = {
  SET_SHOW_GLOBAL_LOADER: 'SET_SHOW_GLOBAL_LOADER',
  INCREMENT_RENDERED_MEMBERS_COUNTER: 'INCREMENT_RENDERED_MEMBERS_COUNTER',
  RESET_RENDERED_MEMBERS_COUNTER: 'RESET_RENDERED_MEMBERS_COUNTER',
  SET_TREE_GEOMETRY: 'SET_TREE_GEOMETRY',
  SET_MEMBER_GEOMETRY: 'SET_MEMBER_GEOMETRY',
  SET_RELATIONS_GEOMETRY: 'SET_RELATIONS_GEOMETRY',
  SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL',
  RESET_MEMBERS_GEOMETRY: 'RESET_MEMBERS_GEOMETRY'
}

function setShowGlobalLoader(value) {
  return {
    type: types.SET_SHOW_GLOBAL_LOADER,
    value
  }
}

function incrementRenderedMembersCounter() {
  return {
    type: types.INCREMENT_RENDERED_MEMBERS_COUNTER
  }
}

function resetRenderedMembersCounter() {
  return {
    type: types.RESET_RENDERED_MEMBERS_COUNTER
  }
}

function setTreeGeometry(value) {
  return {
    type: types.SET_TREE_GEOMETRY,
    value
  }
}

function setMemberGeometry({ id, value }) {
  return {
    type: types.SET_MEMBER_GEOMETRY,
    id,
    value
  }
}

function setRelationsGeometry(value) {
  return {
    type: types.SET_RELATIONS_GEOMETRY,
    value
  }
}

function resetMembersGeometry() {
  return {
    type: types.RESET_MEMBERS_GEOMETRY
  }
}

function onMemberRendered() {
  return (dispatch, getState) => {
    dispatch(incrementRenderedMembersCounter())
    if (areAllMembersRendered(getState()) === true) {
      dispatch(setMembersRendered(true))
    }
  }
}

function saveTreeGeometry(value) {
  return dispatch => {
    dispatch(setTreeGeometry(value))
    dispatch(setTreeGeometryCalculated(true))
  }
}

function saveMemberGeometry(value) {
  return (dispatch, getState) => {
    dispatch(setMemberGeometry(value))
    if (areAllMembersGeometryCalculated(getState()) === true) {
      dispatch(setMembersGeometryCalculated(true))
    }
  }
}

function calculateRelationsGeometry() {
  return (dispatch, getState) => {
    const state = getState()
    const { tree, rolledMembers } = state.family
    const membersGeo = state.ui.membersGeometry
    const geo = []
    const zoomLevel = state.ui.zoomLevel

    const calculateMatrimonyRelationGeometry = (matrimony, startPoint) => {
      const partnerGeo = membersGeo[matrimony.partnerId]
      const relationGeo = [startPoint]
      relationGeo.push({
        x: partnerGeo.left + (partnerGeo.right - partnerGeo.left) / 2,
        y: startPoint.y
      })
      relationGeo.push({
        x: relationGeo[1].x,
        y: relationGeo[1].y + MATRIMONY_STEP_DISTANCE / 2
      })
      geo.push(relationGeo)

      // Matrimony children
      if (matrimony.children && matrimony.children.length > 0) {
        const childrenRelationPoint = {
          x: partnerGeo.left - MEMBER_DISTANCE_X / 2,
          y: partnerGeo.top - MATRIMONY_STEP_DISTANCE / 2
        }
        matrimony.children.forEach(c =>
          !rolledMembers.includes(c.id)
            ? calculateChildRelationGeometry(c, childrenRelationPoint)
            : false
        )
      }
    }

    const calculateChildRelationGeometry = (
      child,
      startPoint,
      hasSingleParent = false
    ) => {
      const childGeo = membersGeo[child.id]
      const endPoint = {
        x: (childGeo.right - childGeo.left) / 2 + childGeo.left,
        y: childGeo.top
      }
      const relationGeo = [startPoint]
      if (startPoint.x !== endPoint.x) {
        const yCoord =
          endPoint.y -
          MEMBER_DISTANCE_Y / 2 -
          (hasSingleParent ? MATRIMONY_STEP_DISTANCE / 2 : 0)
        relationGeo.push({
          x: startPoint.x,
          y: yCoord
        })
        relationGeo.push({
          x: endPoint.x,
          y: yCoord
        })
      }
      relationGeo.push(endPoint)

      geo.push(relationGeo)

      // child group
      calculateGroupRelationsGeometry(child)
    }

    const calculateGroupRelationsGeometry = topMember => {
      const memberGeo = membersGeo[topMember.id]

      // Matrimonies
      if (topMember.matrimonies && topMember.matrimonies.length > 0) {
        const matrimoniesStartPoint = {
          x: memberGeo.right,
          y: memberGeo.top + MATRIMONY_STEP_DISTANCE / 2
        }
        topMember.matrimonies.forEach(m =>
          calculateMatrimonyRelationGeometry(m, matrimoniesStartPoint)
        )
      }

      // Children
      if (topMember.children && topMember.children.length > 0) {
        const childrenRelationPoint = {
          x: (memberGeo.right - memberGeo.left) / 2 + memberGeo.left,
          y: memberGeo.bottom
        }
        topMember.children.forEach(c =>
          !rolledMembers.includes(c.id)
            ? calculateChildRelationGeometry(c, childrenRelationPoint, true)
            : false
        )
      }
    }

    calculateGroupRelationsGeometry(tree)

    // Zoom lever correction
    const zoomCorrectedGeo = geo.map(line =>
      line.map(point => ({
        x: Math.round(point.x / zoomLevel),
        y: Math.round(point.y)
      }))
    )

    dispatch(setRelationsGeometry(zoomCorrectedGeo))
    dispatch(setRelationsGeometryCalculated(true))
  }
}

function setZoomLevel(value) {
  return {
    type: types.SET_ZOOM_LEVEL,
    value: Math.round(value * 100) / 100
  }
}

function incrementZoomLevel() {
  return (dispatch, getState) => {
    const { zoomLevel } = getState().ui
    if (zoomLevel < ZOOM_MAX) {
      dispatch(setZoomLevel(zoomLevel + ZOOM_STEP))
    }
  }
}

function decrementZoomLevel() {
  return (dispatch, getState) => {
    const { zoomLevel } = getState().ui
    if (zoomLevel > ZOOM_MIN) {
      dispatch(setZoomLevel(zoomLevel - ZOOM_STEP))
    }
  }
}

export {
  types,
  setShowGlobalLoader,
  onMemberRendered,
  saveTreeGeometry,
  saveMemberGeometry,
  calculateRelationsGeometry,
  setZoomLevel,
  incrementZoomLevel,
  decrementZoomLevel,
  resetRenderedMembersCounter,
  resetMembersGeometry
}
