const isBrowser = typeof window !== 'undefined' && window.localStorage

const storage = isBrowser ? window.localStorage : null

function getItem(key, defaultValue = null) {
  try {
    return storage
      ? JSON.parse(storage.getItem(key)) || defaultValue
      : defaultValue
  } catch (e) {
    return defaultValue
  }
}

function setItem(key, value) {
  try {
    if (storage) {
      storage.setItem(key, JSON.stringify(value))
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

function removeItem(key) {
  try {
    if (storage) {
      storage.removeItem(key)
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

export { getItem, setItem, removeItem }
