import { makeStyles } from '@material-ui/core/styles'

const getStyle = theme => ({
  root: {
    // minHeight: '100vh'
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 6)
    }
  }
})

export default makeStyles(getStyle)
