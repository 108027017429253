import React, { useRef } from 'react'
import { useStyles } from './style'
import { useGrabbing, useScrollToStartOnRedraw, useGoToMember } from './hooks'
import ZoomContainer from '../Zoomer/ZoomContainer'

function TreeContainer({ noZoom, children }) {
  const classes = useStyles()
  const containerRef = useRef(null)
  useGrabbing(containerRef)

  useScrollToStartOnRedraw(containerRef)

  useGoToMember(containerRef)

  return (
    <div className={classes.root} ref={containerRef}>
      {noZoom ? children : <ZoomContainer>{children}</ZoomContainer>}
    </div>
  )
}

TreeContainer.defaultProps = {
  noZoom: false
}

export default TreeContainer
