import { useDispatch } from 'react-redux'
import { removeItem as removeItemFromStorage } from '../utils/storage'
import { setJwt, setUserData } from '../store/actions/user'

function useLogout() {
  const dispatch = useDispatch()
  function logout() {
    removeItemFromStorage('jwt')
    removeItemFromStorage('userData')
    dispatch(setJwt(null))
    dispatch(setUserData(null))
  }

  return logout
}

export default useLogout
