import { post, put, requestDelete } from '../utils/http'

async function add(body) {
  return await post(`members`, body)
}

async function update(id, body) {
  return await put(`members/${id}`, body)
}

async function remove(id, body) {
  return await requestDelete(`members/${id}`, body)
}

async function uploadImages(id, body) {
  return await post(`members/${id}/images`, body)
}

export { add, update, remove, uploadImages }
