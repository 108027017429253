import { createSelector } from 'reselect'

const treeSelector = state => state.family.tree

const countTreeMembers = createSelector([treeSelector], tree => {
  let i = 0

  function parseMember(item) {
    i += 1
    parseChildren(item.children || [])
    parseMatrimonies(item.matrimonies || [])
  }
  function parseChildren(children) {
    children.forEach(child => parseMember(child))
  }
  function parseMatrimonies(matrimonies) {
    matrimonies.forEach(matrimony => {
      i += 1
      parseChildren(matrimony.children || [])
    })
  }

  if (tree) {
    parseMember(tree)
  }

  return i
})

const getAllParents = createSelector([treeSelector], tree => {
  const parentsGrid = {}
  function parseMember(member) {
    ;(member.children || []).forEach(child => {
      parentsGrid[child.id] = [member.id]
      parseMember(child)
    })
    ;(member.matrimonies || []).forEach(matrimony => {
      parseMatrimony(member.id, matrimony)
    })
  }
  function parseMatrimony(memberId, matrimony) {
    ;(matrimony.children || []).forEach(child => {
      parentsGrid[child.id] = [memberId, matrimony.partnerId]
      parseMember(child)
    })
  }
  if (tree) {
    parseMember(tree)
  }
  return parentsGrid
})

const getAllChildren = createSelector([treeSelector], tree => {
  const childrenGrid = {}
  function parseMember(member) {
    childrenGrid[member.id] = []
    ;(member.children || []).forEach(child => {
      childrenGrid[member.id].push(child.id)
      parseMember(child)
    })
    ;(member.matrimonies || []).forEach(matrimony => {
      const cIds = (matrimony.children || []).map(child => child.id)
      childrenGrid[member.id] = childrenGrid[member.id].concat(cIds)
      childrenGrid[matrimony.partnerId] = cIds
      ;(matrimony.children || []).forEach(child => parseMember(child))
    })
  }
  if (tree) {
    parseMember(tree)
  }
  return childrenGrid
})

// Return a list of id of members of original tree without matrimony partners
const getMainMembers = createSelector([treeSelector], tree => {
  const members = []
  function parseMember(member) {
    members.push(member.id)
    ;(member.children || []).forEach(parseMember)
    ;(member.matrimonies || []).forEach(matrimony =>
      (matrimony.children || []).forEach(parseMember)
    )
  }
  if (tree) {
    parseMember(tree)
  }
  return members
})

const getPartnersList = createSelector([treeSelector], tree => {
  const partners = {}
  function initBag(id) {
    if (!partners[id]) {
      partners[id] = []
    }
    return partners[id]
  }
  function parseMember({ id, matrimonies = [], children = [] }) {
    const bag = initBag(id)
    matrimonies.forEach(({ partnerId, children: matrimonyChildren = [] }) => {
      bag.push(partnerId)
      const partnerBag = initBag(partnerId)
      partnerBag.push(id)
      matrimonyChildren.forEach(parseMember)
    })
    children.forEach(parseMember)
  }
  parseMember(tree)
  return partners
})

const rolldeMembersSelector = state => state.family.rolledMembers

// and partners
const countRolledMembersAndDescendants = createSelector(
  [getAllChildren, getPartnersList, rolldeMembersSelector],
  (childrenGrid, partnersGrid, rolledMembers) => {
    let count = 0
    const last = [...rolledMembers]
    while (last.length > 0) {
      const id = last.shift()
      const children = childrenGrid[id]
      if (children && children.length > 0) {
        last.push(...children)
      }
      const partners = partnersGrid[id]
      if (partners && partners.length > 0) {
        count = count + partners.length
      }
      count++
    }
    return count
  }
)

export {
  countTreeMembers,
  getAllParents,
  getAllChildren,
  getMainMembers,
  getPartnersList,
  countRolledMembersAndDescendants
}
