import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import MemberDetails from '../../dialogs/MemberDetails'
import Roller from '../Roller'
import { useStyles } from './style'
import { useMemberRendered, useCalculateMemberGeometry } from './hooks'

function Member({ memberId, isPartner }) {
  const [showDetails, setShowDetails] = useState(false)
  const member = useSelector(state => state.family.members.get(memberId))
  const classes = useStyles()
  const cardRef = useRef(null)

  useMemberRendered()

  useCalculateMemberGeometry(memberId, cardRef)

  const onMemberClick = e => {
    e.preventDefault()
    setShowDetails(true)
  }

  return (
    <div className={`${classes.root}${isPartner ? ` is-partner` : ''}`}>
      <Card
        className={classes.card}
        ref={cardRef}
        onClick={onMemberClick}
        style={{ cursor: 'pointer' }}
        id={memberId}
      >
        <div className={classes.content}>
          {member.thumbnail && (
            <CardMedia
              image={`${process.env.REACT_APP_UPLOADS_BASE_URL}${member.thumbnail}`}
              className={`${classes.image} ${member.isDead ? classes.bw : ''}`}
            />
          )}
          <div className={classes.details}>
            <Typography variant="subtitle1">{`${member.name}${
              member.lastName ? ` ${member.lastName}` : ''
            }${
              member.maidenName ? ` (${member.maidenName})` : ''
            }`}</Typography>
          </div>

          <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
            {!isPartner && <Roller memberId={memberId} />}
          </div>
        </div>
      </Card>

      <MemberDetails
        open={showDetails}
        onClose={() => setShowDetails(false)}
        member={member}
      />
    </div>
  )
}

export default Member
