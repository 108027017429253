import React from 'react'
import Layout from '../../../components/Layout'
import TreeContainer from '../../../components/TreeContainer'
import TreeComponent from '../../../components/Tree'
import MemberComponent from '../../../components/Member/UpdateMember'
import Zoomer from '../../../components/Zoomer'

const memberComponent = MemberComponent

function UpdateTree() {
  return (
    <Layout>
      <TreeContainer noZoom={false}>
        <TreeComponent updateMode={true} memberComponent={memberComponent} />
      </TreeContainer>
      <Zoomer />
    </Layout>
  )
}

export default UpdateTree
