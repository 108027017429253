import { types } from '../actions/user'
import { user as initialState } from '../initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_JWT:
      return { ...state, jwt: action.value }
    case types.SET_DATA:
      return { ...state, data: action.value }
    case types.SET_AUTHED:
      return { ...state, authed: action.value }
    default:
      return state
  }
}
