const types = {
  ADD_ERROR: 'ADD_ERROR',
  SHIFT_NOTIFICATION: 'SHIFT_NOTIFICATION'
}

function addError(message) {
  return {
    type: types.ADD_ERROR,
    message
  }
}

function shiftNotification() {
  return {
    type: types.SHIFT_NOTIFICATION
  }
}

export { types, addError, shiftNotification }
