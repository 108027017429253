import { useDispatch, useSelector } from 'react-redux'
import { getAllChildren } from '../../store/selectors/family'
import {
  rollChildren,
  unrollChildren,
  unrollDescendants
} from '../../store/actions/family'

function useHasChildren(memberId) {
  const allChildren = useSelector(getAllChildren)
  return allChildren[memberId] && allChildren[memberId].length > 0
}

function useHasGrandchildren(memberId) {
  const allChildren = useSelector(getAllChildren)
  const children = allChildren[memberId]
  if (!children && allChildren[memberId].length === 0) {
    return false
  }
  for (let childId of children) {
    if (allChildren[childId] && allChildren[childId].length > 0) {
      return true
    }
  }
}

function useRollChildren(memberId) {
  const dispatch = useDispatch()
  return () => {
    dispatch(rollChildren(memberId))
  }
}

function useUnrollChildren(memberId) {
  const dispatch = useDispatch()
  return () => {
    dispatch(unrollChildren(memberId))
  }
}

function useUnrollDescendants(memberId) {
  const dispatch = useDispatch()
  return () => {
    dispatch(unrollDescendants(memberId))
  }
}

function useHasChildrenRolled(memberId) {
  const children = useSelector(getAllChildren)[memberId] || []
  const rolledMembers = useSelector(state => state.family.rolledMembers) || []
  return children.some(child => rolledMembers.includes(child))
}

export {
  useHasChildren,
  useHasGrandchildren,
  useRollChildren,
  useHasChildrenRolled,
  useUnrollChildren,
  useUnrollDescendants
}
