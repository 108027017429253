import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './style'
import { usePaint } from './hooks'

function RelationsCanvas() {
  const classes = useStyles()
  const canvas = useRef(null)
  const treeGeo = useSelector(state => state.ui.treeGeometry)

  usePaint(canvas.current)

  return (
    <canvas
      ref={canvas}
      className={classes.root}
      width={treeGeo ? treeGeo.right - treeGeo.left : 0}
      height={treeGeo ? treeGeo.bottom - treeGeo.top : 0}
    />
  )
}

export default RelationsCanvas
