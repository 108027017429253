import { combineReducers } from 'redux'
import app from './app'
import ui from './ui'
import family from './family'
import flags from './flags'
import user from './user'

export default combineReducers({
  ui,
  family,
  app,
  flags,
  user
})
